import React from 'react'
import { FormContainer } from './FormContainer';
import Header from '../Header/index'

function Login({ onchange, value, nextStep, disable }) {
  return (
    <>
      <Header />
      <div className='formContainer'>
        <div className='textWrapper'>
          <h2 className='formHeading'>Welcome to <br /> <span >Elevation <br /> Connect:</span> The Mumbai 2024 Edition</h2>
        </div>
        <div className='outerFormWrapper'>
          <div className='outerWrapper'>
            <p className='formSubheading'>Please fill the below details to proceed to select the portfolio companies you want to meet with</p>
            <FormContainer nextStep={nextStep}
              onchange={onchange}
              form={value}
              disable={disable}
              submit={nextStep} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Login;
