import React, { useEffect, useRef, useState } from 'react'

import Card from '../component/Card';
import { scrollToTop } from '../constant/device';
import { Filter } from '../component/Filter';
import { PopUp } from '../component/PopUp';
import ColorLogoHeader from '../component/Header/ColorLogoHeader';

function Home({
  companies,
  onchange,
  nextStep,
  disable,
  checked,
  handleSelectFilter,
  filter,
  options,
  handleOnChangeFilter
}) {

  const myRef = useRef()

  const [showPopUp, setShowPopUp] = useState(false)

  useEffect(() => {
    setShowPopUp(true)
  }, [])

  useEffect(() => {
    if (myRef.current) {
      scrollToTop(myRef.current.getElementsByClassName('card-wrapper'));
    }
  }, []);

  const popUpClose = () => {
    setShowPopUp(false)
  }

  return (
    <>
      <ColorLogoHeader />
      <p className='selectedSubPara'>
      Below is the list of growth and late-stage companies participating in Elevation Connect's Mumbai 2024 Edition from our diverse portfolio. Please browse through and choose any number of companies you're interested in meeting with by selecting the appropriate company cards. Once you've made your selections, hit the 'Submit' button to confirm.
      </p>
      <div className='option-btn-wrapper flex'>
        <p className='options-btn mobile'>Filter By</p>
        <div className='flex desktop'>
          <button onClick={() => handleOnChangeFilter('')} className={`read-more options-btn ${filter === '' ? 'option-active primary' : ''}`}>All</button>
          {
            options && options.map((option, i) => (
              <button onClick={() => handleOnChangeFilter(option)} className={`read-more options-btn ${filter === option ? 'option-active primary' : ''}`} key={i}>{option}</button>
            ))
          }
        </div>
        <div className='mobile'>
          <Filter handleSelectFilter={handleSelectFilter} filter={filter} options={options} />
        </div>
      </div>
      <div ref={myRef} className="card-wrapper">
        {
          companies ? companies.map((data, i) => (
            <Card
              filter={filter}
              key={i}
              data={data}
              onchange={onchange}
              checked={checked[data.fields.Name] || false} />
          )) : <h2> loading </h2>
        }
      </div>
      <button disabled={disable} onClick={nextStep} className={`next-btn mars ${disable ? 'disable' : 'active'}`}>Submit</button>
      <button disabled={disable} onClick={nextStep} className={`fix-next-btn next-btn mars ${disable ? 'disable' : 'active'}`}>NEXT</button>
      {
        showPopUp && <PopUp onClose={popUpClose} />
      }
    </>
  )
}

export default Home;
