import React from 'react'

import Login from '../component/Login'
import Home from './Home'
import {SelectedCompanies} from './SelectedCompanies'

function Dashboard({
  step,
  onchange,
  values,
  onsubmit,
  disable,
  nextStep,
  prevStep,
  allCompanies,
  disableAllCompanies,
  getSelectedCompanies,
  checked,
  loading,
  handleSelectFilter,
  filter,
  options,
  showThankYouModal,
  handleOnChangeFilter
 }) {
  switch (step) {
    case 1:
      return (
        <Login
          onchange={onchange}
          value={values}
          nextStep={nextStep}
          disable={disable}
        />
      )
    case 2:
      return (
        <Home
          companies={allCompanies}
          nextStep={nextStep}
          onchange={onchange}
          disable={disableAllCompanies}
          checked={checked}
          handleSelectFilter={handleSelectFilter}
          filter={filter}
          options={options}
          handleOnChangeFilter={handleOnChangeFilter}
        />
      )
    case 3:
      return (
        <SelectedCompanies
          handleBackPage={prevStep}
          handleSelectedSubmit={onsubmit}
          companies={getSelectedCompanies}
          onchange={onchange}
          loading={loading}
          showThankYouModal={showThankYouModal}
        />
      )
    default:
  }

}

export default Dashboard;
