import React from "react";

export const PopUp = ({ onClose }) => (
  <div className="modal-overlay flex modal-open">
    <div className="modal-wrapper flex align-center popup-wrapper">
      <button onClick={onClose} className='modal-close white read-more' >X</button>
      <p className='modal-content white obj modal-company-content popup-content'>
        Here is the list of companies participating in Elevation Connect's Mumbai 2024 Edition from our diverse portfolio.</p>
      <p className='modal-content white obj modal-company-content popup-content'>
        Please browse through and choose any number of companies you're interested in meeting with by selecting the appropriate company cards. Once you've made your selections, hit the 'Submit' button to confirm.
      </p>
    </div>
  </div>
)
