import React, { useEffect, useState } from "react";
import axios from "axios";

// import Header from "./component/Header";
import Dashboard from "./container/Dashboard";
import { BASE_ID_USER, API_KEY, INVESTORS_TABLE, BASE_API, COMPANIES_TABLE } from './constant/index';

import './assets/style/style.css';
import Footer from "./component/Footer";

function App() {
  const [allCompanies, setAllCompanies] = useState([])
  const [form, setForm] = useState({
    Name: '',
    Email: '',
    Company: '',
    Designation: '',
    // SelectTimeZone: '',
    // OtherTimeZone: '',
    selectedCompanies: [],
  })
  const [showError, setShowError] = useState(false)
  const [step, setStep] = useState(1)
  const [checked, setChecked] = useState({})
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [showThankYouModal, setShowThankYouModal] = useState(false)

  const getCompanies = () => {
    axios.get(`${BASE_API}/${BASE_ID_USER}/${COMPANIES_TABLE}?sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc&fields[]=Name&fields[]=website&fields[]=Description&fields[]=about&fields[]=stage&fields[]=sector&fields[]=last_raised&fields[]=logo&fields[]=LogoSize`,
      { headers: { "Authorization": `Bearer ${API_KEY}` } })
      .then(res => setAllCompanies(res.data.records))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getCompanies();
  }, [filter]);

  const handleFormChange = (field) => (e) => {
    const { value, checked } = e.target;

    setChecked((currentState) => ({
      ...currentState,
      [e.target.name]: e.target.checked
    }));

    const { selectedCompanies } = form

    if (field === "selectedCompanies" && checked) {

      setForm((currentState) => ({
        ...currentState,
        [field]: [...selectedCompanies, value]
      }));

    } else if (field === 'selectedCompanies') {
      setForm((currentState) => ({
        ...currentState,
        [field]: selectedCompanies.filter((e) => e !== value)
      }));

    } else {
      setForm((currentState) => ({
        ...currentState,
        [field]: value
      }));
    }
  }

  let getSelectedCompanies = []

  form.selectedCompanies.forEach((e) => allCompanies.forEach((company) => {
    if (company.id === e) {
      getSelectedCompanies.push(company)
    }
  }))

  const handleOnChangeFilter = (value) => {
    setFilter(value)
  }

  const handleSelectFilter = (e) => {
    const { value } = e.target
    setFilter(value)
  }

  const getErrors = (form) => {
    const errors = {};

    if (!form.Name) {
      errors.Name = "Enter Name"
    }

    if (!form.Company) {
      errors.Company = "Enter company name"
    }

    if (!form.Email) {
      errors.Email = "Enter Email name"
    }
    return errors
  }

  const handleSubmitFrom = async (e) => {
    e && e.preventDefault();
    setLoading(true)
    const errors = getErrors(form);

    if (Object.keys(errors).length > 0) {
      !showError && setShowError(true);
      return;
    }

    axios.post(`${BASE_API}/${BASE_ID_USER}/${INVESTORS_TABLE}/`,
      {
        "fields": {
          "Name": form.Name,
          "Company": form.Company,
          "selectedCompanies": form.selectedCompanies,
          "Email": form.Email,
          "Designation": form.Designation,
          // "SelectTimeZone": form.SelectTimeZone,
          // "OtherTimeZone": form.OtherTimeZone,
        },
      }, { headers: { "Authorization": `Bearer ${API_KEY}` } }).then((res) => setShowThankYouModal(true)).catch(err => console.log(err))
  }

  const nextStep = () => {
    setStep(step + 1);
    window.scrollTo(0, 0)
  }

  const prevStep = () => {
    setStep(step - 1);
  }

  const disable = form.Name === '' || form.Company === '' || form.Email === ''
  const disableAllCompanies = form.selectedCompanies.length === 0

  const filterCompanies = allCompanies.filter(com => com.fields.sector === filter)

  const showCompanies = filterCompanies.length > 0 ? filterCompanies : allCompanies

  const options = allCompanies && allCompanies.map((e) => e.fields?.sector)
  const optionList = Array.from(new Set(options))

  return (
    <>
      {/* <Header /> */}
      <Dashboard
        step={step}
        onchange={handleFormChange}
        values={form}
        onsubmit={handleSubmitFrom}
        disable={disable}
        nextStep={nextStep}
        prevStep={prevStep}
        allCompanies={showCompanies}
        disableAllCompanies={disableAllCompanies}
        getSelectedCompanies={getSelectedCompanies}
        checked={checked}
        loading={loading}
        handleSelectFilter={handleSelectFilter}
        filter={filter}
        options={optionList}
        showThankYouModal={showThankYouModal}
        handleOnChangeFilter={handleOnChangeFilter}
      />
      <Footer/>
    </>
  );
}

export default App;
