import React, { useEffect } from 'react'

import Card from '../component/Card'
import { scrollToTop } from '../constant/device'
import ColorLogoHeader from '../component/Header/ColorLogoHeader'

export const SelectedCompanies = ({ companies, handleBackPage, handleSelectedSubmit, onchange, loading, handleClickReadMore, handleClickReadLess, showAbout, showThankYouModal }) => {

  const disable = companies.length === 0

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <>
      <ColorLogoHeader />
      <div className='selectedCompaniesWrapper'>
        <h2 className='title sec mars'>Shortlisted Companies</h2>
        <div className="card-wrapper">
          {
            companies ? companies.map((data, i) => (
              <Card
                handleClickReadMore={handleClickReadMore}
                handleClickReadLess={handleClickReadLess}
                showAbout={showAbout}
                key={i} data={data} onchange={onchange} checked={true} />
            )) : <p>Loading .....</p>
          }
        </div>
        <button onClick={handleBackPage} className='next-btn mars selected-companies-btn active'>Back</button>
        <button disabled={disable || loading} onClick={handleSelectedSubmit} className={`next-btn mars selected-companies-btn ${disable ? 'disable' : 'active'}`}>Submit</button>
        <button onClick={handleBackPage} className='next-btn fix-next-btn mars selected-companies-btn active fix-back-btn'>Back</button>
        <button disabled={disable || loading} onClick={handleSelectedSubmit} className={`next-btn mars fix-next-btn selected-companies-btn ${disable ? 'disable' : 'active'}`}>Submit</button>
        <div>
          {showThankYouModal && (
            <div className="modal-overlay flex align-center modal-open">
              <div className="modal-wrapper flex align-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" viewBox="0 0 192 192" fill="none">
                  <g filter="url(#filter0_d_2018_2156)">
                    <path d="M96.12 161.25C59.89 161.25 30.53 131.88 30.53 95.6601C30.53 59.4401 59.89 30.0601 96.12 30.0601C132.35 30.0601 161.71 59.4301 161.71 95.6501C161.71 131.87 132.34 161.24 96.12 161.24V161.25ZM89.35 120.61L129.48 79.7101C131.77 77.3701 131.74 73.6201 129.4 71.3201C127.06 69.0301 123.31 69.0601 121.01 71.4001L84.91 108.19L71.01 95.3701C68.6 93.1501 64.85 93.3001 62.63 95.7101C60.41 98.1201 60.56 101.87 62.97 104.09L81.09 120.81C82.23 121.86 83.67 122.38 85.11 122.38C86.65 122.38 88.18 121.78 89.34 120.6L89.35 120.61Z" stroke="white" strokeWidth="3.81" strokeMiterlimit="10" />
                  </g>
                  <defs>
                    <filter id="filter0_d_2018_2156" x="9.965" y="9.49527" width="172.31" height="172.32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="9.33" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2018_2156" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2018_2156" result="shape" />
                    </filter>
                  </defs>
                </svg>
                <h2 className='modal-heading white mars'>THANK YOU!</h2>
                {/* <p className='modal-content'>The event is scheduled from March 6th - 17th 2023. The meetings would be held between 8 am to 10 pm IST. </p>
              <p className='modal-content'>We will work with the timezones you have mentioned and block calendar slots accordingly. The meetings will be conducted over Zoom and will be 45 minutes long,with 15 minute breaks in between.</p> */}
                <p className='modal-content white obj'>Thank you for choosing the companies you wish to meet with. We’ll be in touch with you soon with the detailed schedule for the meetings based on double opt-in.</p>
                <p className='modal-content white obj'>Your participation is greatly appreciated, and we look forward to facilitating these valuable interactions.</p>
                <a className='modal-btn align-center flex white mars' href='https://elevationcapital.com/' target='_blank' rel="noreferrer">
                  VISIT ELEVATION CAPITAL WEBSITE
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
