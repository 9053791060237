import React, { useState } from 'react'

function Card({ data, onchange, checked }) {
  const { Name, logo, website, stage, sector, last_raised, about, Description, LogoSize } = data.fields
  const [show, setShow] = useState(false)

  const onReadMore = () => {
    setShow(true)
  }

  const onClose = () => {
    setShow(false)
  }

  const paragraphs = about && about.split('\n').map((item, key) => {
    return <p key={key} style={{ paddingBottom: '8px' }} >{item}</p>;
  });

  return (
    <>
      <div className={`card flex direction-column ${checked ? 'selectedCard' : ''}`}>
        <div className='card-details flex direction-column'>
          <div className='card-item-end'>
            <input
              id={Name}
              type="checkbox"
              checked={checked}
              name={Name}
              value={data.id || ""}
              onChange={onchange('selectedCompanies')}
              style={{
                width: '27px',
                height: '27px',
                borderRadius: '50%',
                appearance: 'none',
                // border: '2px solid #3282ff',
                backgroundColor: checked ? '#1dc115' : '#fff',
                cursor: 'pointer',
              }}
            />
          </div>
          <label htmlFor={Name}></label>
        
          <h2 className='card-title company-name obj'>{Name}</h2>

          <div className='flex card-details-sector-company'>
            <div className='w-60'>
              <p className='card-subheading'>SECTOR</p>
              <p className='card-title card-details-sector  obj'>{sector}</p>
            </div>
            <div className='website-wrapper'>
              <a className='company-link obj' href={website} target='_blank' rel="noreferrer">VISIT WEBSITE  <svg width="8" height="8" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 5.74L4.53 1.21L4.5 1.17L0.77 1.23L2 0L6.54 0.05L6.59 4.59L5.36 5.84L5.42 2.11L5.38 2.08L0.85 6.59L0 5.74Z" fill="white" />
              </svg></a>
            </div>
          </div>

        </div>
        <div className='details-wrapper'>
          <div className='flex'>
            <div className='w-50'>
              <p className='card-subheading'>STAGE</p>
              <p className='card-title  obj'>{stage}</p>
            </div>

            <div>
              <p className='card-subheading'>LAST ROUND RAISED</p>
              <p className='card-title  obj'>$ {last_raised}</p>
            </div>
          </div>

          <div style={{ width: `${LogoSize}%` }} className='company-logo-wrapper'>
            {logo && logo.map((e, i) => <img key={i} className='company-logo' src={e.url} alt="logo" />)}
          </div>

          <p className='about-company obj'>{Description}</p>
          <div className='flex flex-sb align-center last-item'>
            <button onClick={onReadMore} className='read-more primary'>Read More</button>
          </div>
        </div>
      </div>

      {show && (
        <div className="modal-overlay flex align-center modal-open">
          <div className="modal-wrapper flex align-center modal-for-company">
            <button onClick={onClose} className='modal-close white read-more' >X</button>
            <h2 className='modal-heading white mars'>{Name}</h2>
            <p className='modal-content white obj modal-company-content'>{paragraphs}</p>
            <a className='modal-btn align-center flex white mars capital' href={website} target='_blank' rel="noreferrer">
              Visit {Name} Website
            </a>
          </div>
        </div>
      )}
    </>
  )
}

export default Card;
