import React from 'react'

import Logo from '../../assets/media/logo2.png'

function Header() {
  return (
    <header className='header'>
      <img className='logo' src={Logo} alt="logo" />
    </header>
  )
}

export default Header;
