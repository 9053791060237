import React from 'react'

function Footer() {
  return (
    <div className='footer flex align-center flex-sb'>
     <p className='footer-title white mars'>BUILT FOR THE AUDACIOUS</p>
     <p className='copyright obj'>© 2024 Elevation Capital. All rights reserved</p>
    </div>
  )
}

export default Footer;
