import React from 'react'


export const Filter = ({ handleSelectFilter, filter, options }) => {
	return (
		<>
			<select value={filter} className='select-option' onChange={handleSelectFilter}>
				{/* <option key='blankKey' hidden value >Select Sector</option> */}
				<option key='blankKey1' value={''} >All</option>
				{
					options && options.map((e, i) => (
						<option key={i} value={e}>{e}</option>
					))
				}
			</select>
		</>
	)
}
