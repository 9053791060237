import React from "react";
// import { TIMEZONE } from "../../constant/timezone";

export const FormContainer = ({ onchange, form, disable, submit }) => (
  <form onSubmit={submit} action="" className='formWrapper'>
    <div>
      <input
        className='input'
        type="text"
        name="Name"
        placeholder="Name"
        value={form.Name}
        onChange={onchange("Name")}
      />
    </div>
    <div>
      <input
        className='input'
        type="email"
        name="Email"
        placeholder="Email Id"
        value={form.Email}
        onChange={onchange('Email')}
      />
    </div>
    <div>
      <input
        className='input'
        type="text"
        name="Company"
        placeholder="Company Name"
        value={form.Company}
        onChange={onchange('Company')}
      />
    </div>
    <div>
      <input
        className='input'
        type="text"
        name="Designation"
        placeholder="Designation"
        value={form.Designation}
        onChange={onchange('Designation')}
      />
    </div>
    {/* <div>
      <select
        className='input select'
        value={form.SelectTimeZone}
        onChange={onchange('SelectTimeZone')}
        aria-label="Select Time Zone"
      >
        <option className='hidden-option' key='blankKey' hidden>
          Select Time Zone
        </option>
        {TIMEZONE &&
          TIMEZONE.map((e, index) => (
            <option key={index} value={e.value}>
              {e.key}
            </option>
          ))}
      </select>
    </div>
    {
      form.SelectTimeZone && form.SelectTimeZone === 'others' &&
      <div>
        <input
          className='input'
          type="text"
          name="OtherTimeZone"
          placeholder="Please mention your timezone"
          value={form.OtherTimeZone}
          onChange={onchange('OtherTimeZone')}
        />
      </div>
    } */}
    <div>
      <input
        className={`btn ${disable ? 'disabled' : ''}`}
        type="submit"
        value="Next"
        disabled={disable}
      />
    </div>
  </form>
);
