import React from 'react'

import Logo from '../../assets/media/colorLogo.png'

function ColorLogoHeader() {
  return (
    <header className='header'>
      <img className='logo' src={Logo} alt="logo" />
    </header>
  )
}

export default ColorLogoHeader;
